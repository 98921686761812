<template>
	<base-col col="12" :lg="lgColLength">
		<div class="product-content">
			<base-font class="title" variant="paragraph" tag="h2" weight="bold" uppercase>
				{{value.heading}}
			</base-font>
			<div class="product-text" v-html="value.paragraph"></div>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		lgColLength () {
			const cardIndex = parseInt(this.prefix.match(/\d/)[0])
			return String(cardIndex === 1 ? 8 : 4)
		}
	}
}
</script>
<style lang="scss" scoped>
.product-content {
	background: #fff;
	padding: 3rem;
	border: 1px solid $gray-100;
	margin-top: 4rem;

	@include media-breakpoint-up(lg) {
		min-height: 310px;
	}

	@include media-breakpoint-up(xl) {
		margin-top: 8rem;
		min-height: 260px;
		height: calc(100% - 8rem);;
	}

	& :deep(.title) {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		&::after {
			content: '';
			flex-grow: 1;
			margin-left: 2rem;
			height: 2px;
			background-color: #F0EEEC;
		}
	}
	.product-text {
		font-family: $primary-font;
	}
}
</style>
